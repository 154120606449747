// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyDtMIWch78paTANZC0iq1kASmKoWlYuaS8",
    authDomain: "japan-ef2d4.firebaseapp.com",
    projectId: "japan-ef2d4",
    storageBucket: "japan-ef2d4.appspot.com",
    messagingSenderId: "871617960333",
    appId: "1:871617960333:web:e92b335b01aed0bb6885c4",
    measurementId: "G-VY81KVTLSN"
  },
  NODE_TLS_REJECT_UNAUTHORIZED:0
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
