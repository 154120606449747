import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Admin } from '../model/admin.model';
import { TokenStorageService } from './token-storage.service';


import { ServerConfig } from './serverconfig';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';

import * as firebase from 'firebase';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  AUTH_API: string;

  loginid ="";
  loginname ="";

  constructor(
    private http: HttpClient,
    public DB: AngularFirestore,
    private tokenStorageService: TokenStorageService,
    public serverConfig: ServerConfig
    ) {
      this.AUTH_API = this.serverConfig.server_auth_api;
    }

    setLoginid(id){
      this.loginid = id;
    }

    getLoginid(){
      return this.loginid;
    }

    setLoginname(name){
      this.loginname = name;
    }

    getLoginname(){
      return this.loginname;
    }


    cybermoneyforproductpurchase(orderid, point, info){

      let currentday = moment().format('YYYY년MM월DD일');
      let currentdate = moment().format('YYYY년MM월DD일 HH:mm');

      
      // 이력관리
      this.DB.collection("cybermoneyhistory").add({
        createtime : currentdate,
        cybermoney : point,
        orderid : orderid,
        user_id : this.getLoginid(),
        user_name : this.getLoginname(),
        cyberinfo : info,
        usersession : firebase.auth().currentUser.uid   
      }).then((result)=>{
        this.DB.collection("cybermoneyhistory").doc(result.id).update({
          cybermoneyhistoryid : result.id
        }).then((result)=>{

          this.DB.collection("user", 
          ref=>ref.where("usersession", "==", firebase.auth().currentUser.uid)).get().subscribe((result)=>{
            point = parseInt(point) * -1;

            result.forEach((element)=>{
              this.DB.collection("user").doc(element.id).update({
                user_cybermoney : firebase.firestore.FieldValue.increment(point)
              })
            })
    
          })



        })
      })



    }

  

  
}
