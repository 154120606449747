import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent} from './theme/layout/auth/auth.component';
import { SessionGuardService as AuthGuard} from './service/session-guard.service';

const routes: Routes = [
  {
    path :'',
    children  :[
      {
        path: 'header', 
        loadChildren: () => import('../app/pages/headerpage/headerpage.module').then(module => module.HeaderPageModule)
      },
      {
        path: 'guide', 
        loadChildren: () => import('../app/pages/guidepage/guidepage.module').then(module => module.GuidepageModule)
      },
      {
        path: 'proxy', 
        loadChildren: () => import('../app/pages/proxypage/proxypage.module').then(module => module.ProxyPageModule)
      },
      {
        path: 'deliveryproxy', 
        loadChildren: () => import('../app/pages/deliveryproxypage/deliveryproxypage.module').then(module => module.DeliveryProxyPageModule)
      },
      {
        path: 'cybermoney', 
        loadChildren: () => import('../app/pages/cybermoneypage/cybermoneypage.module').then(module => module.CyberMoneyPageModule)
      },
      {
        path: 'community', 
        loadChildren: () => import('../app/pages/communitypage/communitypage.module').then(module => module.CommutiyPageModule)
      },
      {
        path: 'japan-main', loadChildren: () => import('../app/pages/japan-main/japan-main.module').then(module => module.JapanMainModule)
      },
      {
        path: 'japan-maintmp', loadChildren: () => import('../app/pages/japan-maintmp/japan-maintmp.module').then(module => module.JapanMaintmpModule)
      },
      {
        path: 'register', loadChildren: () => import('../app/pages/register/register.module').then(module => module.RegisterModule)
      }
    ]
  }


 ];



@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }




